.w-md-editor-text-input {
  -webkit-text-fill-color: black;
  background: white;
  white-space: inherit;
  padding: 10px;
}

.wmde-markdown {
  background-color: white !important;
  color: black !important;
}

.w-md-editor-toolbar {
  background-color: white !important;
  border-bottom: 1px solid #d0d7de;
}
.w-md-editor-toolbar li > button {
  color: #586069 !important;
}

.wmde-markdown table th,
.wmde-markdown table td {
  background: white;
}

.w-md-editor {
  box-shadow: 0 0 0 1px rgb(235, 230, 230);
  --md-editor-box-shadow-color: #d0d7de;
}

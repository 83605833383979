.simba_georgia_page,
.simba_page_area {
  width: 300mm !important;
}

.simba_page {
  width: 290mm !important;
  line-height: 1 !important;
}

.variable-chip-container {
  height: 20px;
  border-radius: 16px;
  max-width: 100%;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.87);
  background-color: #00000014;
  white-space: nowrap;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}

.variable-chip {
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}
